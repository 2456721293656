<template>
 <div>
        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>
    <div class="row">
        <div class="col-md-12">
            <CCard>
                <CCardBody>
                    <CForm novalidate>
                        <div class="d-flex mt-3 mb-3">
                            <img :src="file" class="avatar ml-3 mb-3" />
                            <div class="form-group form-actions mt-5 pt-4">
                                <input
                                    id="fileUpload"
                                    type="file"
                                    @change="saveImage"
                                    hidden
                                />
                                <CButton
                                    size="sm"
                                    color="primary"
                                    class="ml-3"
                                    @click="chooseFiles"
                                >
                                    Change image
                                </CButton>
                                <!-- <CButton
                                    v-else
                                    size="sm"
                                    color="primary"
                                    class="ml-3"
                                    @click="saveFiles"
                                >
                                    Save image
                                </CButton> -->
                            </div>
                        </div>
                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Corp. name
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    value="Bank 1"
                                    required
                                    was-validated
                                    v-model="updateProfile.company_name"
                                />
                                <template v-if="!updateProfile.company_name && buttonClick== true">
                                    <p class="errorMessage">Corp name is required</p>
                                </template>
                            </div>
                        </div>


                        
                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Type of user
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CSelect
                                    :options="category"
                                    placeholder="Select user type"
                                    required
                                    was-validated
                                    v-model="profile.company['id']"
                                />
                                <template v-if="!updateProfile.company && buttonClick== true">
                                    <p class="errorMessage">Select type of user</p>
                                </template>
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                First name
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    value="Bank 1"
                                    required
                                    was-validated
                                    v-model="updateProfile.first_name"
                                />
                                <template v-if="!updateProfile.first_name && buttonClick== true">
                                    <p class="errorMessage">First name is required</p>
                                </template>
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                Last name
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    v-model="updateProfile.last_name"
                                />
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Email
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    required
                                    was-validated
                                    v-model="updateProfile.email"
                                />
                                <template v-if="!updateProfile.email && buttonClick== true">
                                    <p class="errorMessage">Email is required</p>
                                </template>
                            </div>
                        </div>


                        <div class="form-group d-flex mb-0">
                            <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                Contact
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput v-model="updateProfile.phone" />
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                City
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput v-model="updateProfile.city" />
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                State
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    required
                                    was-validated
                                    v-model="updateProfile.state"
                                />
                                <template v-if="!updateProfile.state && buttonClick== true">
                                    <p class="errorMessage">State is required</p>
                                </template>
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Postal code
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput 
                                required 
                                was-validated
                                v-model="updateProfile.postal"
                                />
                                <template v-if="!updateProfile.postal && buttonClick== true">
                                    <p class="errorMessage">Postal code is required</p>
                                </template>
                            </div>
                        </div>

                        <!-- <div class="mb-4 d-flex align-items-center">
                            <CCol
                                tag="label"
                                class="
                                    col-xl-4 col-6 col-lg-5 col-md-6 col-sm-12
                                    mb-0
                                "
                            >
                                Status
                            </CCol>
                            <CCol
                                class="
                                    d-flex
                                    col-8 col-md-8 col-lg-9 col-xl-10 col-sm-12
                                    align-items-center
                                "
                            >
                                <h6 class="mb-0">Inactive</h6>
                                <CSwitch
                                    class="mt-1 ml-2 mr-2"
                                    color="primary"
                                    shape="pill"
                                    v-model="updateProfile.is_active"
                                    :checked="updateProfile.is_active"
                                    @update:checked="
                                        updateProfile.is_active =
                                            !updateProfile.is_active
                                    "
                                />
                                <h6 class="mb-0">Active</h6>
                            </CCol>
                        </div> -->
                    </CForm>

                    <div class="form-group form-actions float-right">
                        <router-link to="/bankProfile">
                            <CButton type="submit" size="sm" color="danger">
                                Cancel
                            </CButton>
                        </router-link>
                        <CButton
                            type="submit"
                            size="sm"
                            color="primary"
                            class="ml-3"
                            @click="errorCheck()"
                        >
                            Update
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    </div>
 </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const profileUpdateUrl = URL + `${"profile/bankuser/profile/"}`;
const bankCategoryUrl = URL + `${"profile/bank/bashboard/category/"}`;


export default {
    name: "EditBankUserProfile",
    props: ["profile"],
    data() {
        return {
            buttonClick: false,
            updateProfile: {
                company_name: "",
                company: "",
                first_name: "",
                last_name: "",
                email: "",
                city: "",
                state: "",
                country: "",
                postal: "",
                postal: "",
                phone: "",
                picture: "",
                is_active: null,
            },
            file: null,
            category: [],
            errorMessage: "",
            checkError: false,
        };
    },
    methods: {
        chooseFiles() {
            document.getElementById("fileUpload").click();
        },
        errorCheck(){
            this.buttonClick= true;

            if(this.updateProfile.company_name.trim().length!= 0 && this.updateProfile.company.length!= 0 &&
             this.updateProfile.first_name.trim().length!= 0 && this.updateProfile.email.trim().length!= 0
             && this.updateProfile.state.trim().length!= 0 && this.updateProfile.postal.trim().length!= 0 ){
                    this.buttonClick= false;
                    this.saveUserData();
               }
        },

        saveImage(event) {
            this.updateProfile.picture = event.target.files[0];
            // this.updateProfile.picture = this.$refs.file.files.item(0);
            var reader = new FileReader();
            reader.readAsDataURL(this.updateProfile.picture);
            reader.onload = (e) => {
                this.file = e.target.result;
            };
        },
        // saveFiles() {
        //     let form_data = new FormData();
        //     form_data.append("picture", this.file);
        //     this.saveUserData(form_data);
        // },

        saveUserData() {
            const formData = new FormData();
            formData.append("company_name", this.updateProfile.company_name);
            formData.append("company", this.updateProfile.company['id']);
            formData.append("first_name", this.updateProfile.first_name);
            formData.append("last_name", this.updateProfile.last_name);
            formData.append("email", this.updateProfile.email);
            formData.append("birthday", this.updateProfile.birthday);
            formData.append("city", this.updateProfile.city);
            formData.append("state", this.updateProfile.state);
            formData.append("country", this.updateProfile.country);
            formData.append("postal", this.updateProfile.postal);
            formData.append("phone", this.updateProfile.phone);
            // formData.append("gender", this.updateProfile.gender);
            formData.append("picture", this.updateProfile.picture);
            formData.append("is_active", this.updateProfile.is_active);
            this.axios
                .put(profileUpdateUrl, formData, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.setItem("token", response.data.access_token);
                    this.$router.push("/bankProfile");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
        getBankCategory() {
            this.axios
                .get(bankCategoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: "all" },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.category = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
    },
    beforeMount() {
        this.getBankCategory();
    },
    mounted() {
        if (this.profile) {
            this.updateProfile = this.profile;
            this.file = this.profile.picture;
        }
    },
};
</script>